import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import {
  ActivityOptionsResponse,
  ActivityPreviewPhotosResponse,
  PreviewPhotos,
} from './interface';
import { format, parseISO } from 'date-fns';
import { Option } from '@/components/base/BaseMenu/BaseMenu';

const URL = '/api/activity';

export const activityAPI = createApi({
  reducerPath: 'activityAPI',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getQueryActivityOptionsByFilter: builder.query<Option[], any>({
      query: (data) => ({
        url: `${URL}/Mock/QueryActivityOptionsByFilter`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: any) => {
        const optionData = response.data.data.map((e) => ({
          label: e.name,
          value: e.currentQtyPercentage,
          id: e.activityId,
        }));
        return optionData;
      },
    }),
    getQueryActivityPreviewPhotosByFilter: builder.query<PreviewPhotos[], any>({
      query: (data) => ({
        url: `${URL}/Mock/QueryActivityPreviewPhotosByFilter`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
    getQueryLatestActivityPhotoByFilter: builder.query<any, any>({
      query: (data) => ({
        url: `${URL}/QueryLatestActivityPhotoByFilter`,
        method: 'GET',
        params: data,
      }),
      transformResponse: (response: any) => {
        // 按照日期分組
        const groupedPhotos = response.data.data.reduce((acc, photo) => {
          const date = format(parseISO(photo.created), 'yyyy-MM-dd');
          if (!acc[date]) {
            acc[date] = {
              date,
              photos: [],
            };
          }
          acc[date].photos.push(photo);
          return acc;
        }, {});

        // 把結果轉成 Array
        const result = Object.values(groupedPhotos);
        return result;
      },
    }),
  }),
});

export const {
  useGetQueryActivityOptionsByFilterQuery,
  useGetQueryActivityPreviewPhotosByFilterQuery,
  useGetQueryLatestActivityPhotoByFilterQuery,
} = activityAPI;
