import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ProjectDetail,
  ProjectDetailResponse,
  SelfProjects,
  SelfProjectsResponse,
} from './Interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

const URL = '/api/project';

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSelfProjects: builder.query<SelfProjects[], number>({
      query: (groupId) => ({
        url: `${URL}/GetSelfProjects`,
        method: 'GET',
        params: { GroupId: groupId },
      }),
      transformResponse: (response: SelfProjectsResponse) => response.data.data,
    }),
    getProjectDetail: builder.query<ProjectDetail, number>({
      query: (projectId) => ({
        url: `/api/Project/GetProjectDetail`,
        method: 'GET',
        params: { projectId: projectId },
      }),
      transformResponse: (response: ProjectDetailResponse) =>
        response.data.data,
    }),
  }),
});

export const { useGetSelfProjectsQuery, useGetProjectDetailQuery } = projectsApi;
