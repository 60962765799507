import { createSlice } from '@reduxjs/toolkit';

import {
  GetNewsfeedDetailThunk,
  queryNewsfeedAndWeeklyReportWithPagedThunk,
} from '@/lib/newsfeed/newsfeedAction';

interface InitialState {
  deatil: any;
  newsFeeds: any[];
  weeklyReports: any[];

  dataApiStatus: 'pending' | 'succeed' | 'failed' | 'empty';
  deatilApiStatus: 'pending' | 'succeed' | 'failed' | 'empty';

  currentPageCount: number;
  pageCount: number;
  pageNumber: number;
  totalCount: number;
  totalPageCount: number;
}

const initialState: InitialState = {
  dataApiStatus: 'empty',
  deatil: {},
  deatilApiStatus: 'empty',

  newsFeeds: [],
  weeklyReports: [],

  currentPageCount: 0,
  pageCount: 0,
  pageNumber: 0,
  totalCount: 0,
  totalPageCount: 0,
};

const newsfeedSlice = createSlice({
  name: 'newsfeed',
  initialState,
  reducers: {
    resetState: () => initialState,
    setNoteToStore: (state, action) => {
      const { order, note } = action.payload;
      state.deatil.photos[order].notes = note;
    },
    setPhotoNameToStore: (state, action) => {
      const { order, name } = action.payload;
      state.deatil.photos[order].photoName = name;
    },
    removePhotoFromStore: (state, action) => {
      const { photoId } = action.payload;
      state.deatil.photos = state.deatil.photos.filter(
        (v) => v.photoId !== photoId,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // GetNewsfeedDetailThunk
      .addCase(GetNewsfeedDetailThunk.pending, (state, action) => {
        state.dataApiStatus = 'pending';
      })
      .addCase(GetNewsfeedDetailThunk.fulfilled, (state, action) => {
        state.deatil = action.payload;
        state.deatilApiStatus = 'succeed';
      })
      .addCase(GetNewsfeedDetailThunk.rejected, (state, action) => {
        state.deatilApiStatus = 'failed';
      })
      // GetNewsfeedDetailThunk
      .addCase(
        queryNewsfeedAndWeeklyReportWithPagedThunk.pending,
        (state, action) => {
          state.dataApiStatus = 'pending';
        },
      )
      .addCase(
        queryNewsfeedAndWeeklyReportWithPagedThunk.fulfilled,
        (state, action) => {
          // console.debug(state.newsFeeds)
          const { newsFeeds, weeklyReports } = action.payload;
          if (newsFeeds.pageNumber === 1) {
            state.newsFeeds = newsFeeds.data;
            state.weeklyReports = weeklyReports;
          } else if (newsFeeds.pageNumber > 1) {
            state.newsFeeds = state.newsFeeds.concat(newsFeeds.data);
            state.weeklyReports = state.weeklyReports.concat(weeklyReports);
          }
          state.currentPageCount = newsFeeds.currentPageCount;
          state.pageCount = newsFeeds.pageCount;
          state.pageNumber = newsFeeds.pageNumber;
          state.totalCount = newsFeeds.totalCount;
          state.totalPageCount = newsFeeds.totalPageCount;
          state.dataApiStatus = 'succeed';
        },
      )
      .addCase(
        queryNewsfeedAndWeeklyReportWithPagedThunk.rejected,
        (state, action) => {
          state.deatilApiStatus = 'failed';
        },
      );
  },
});

export const {
  resetState,
  setNoteToStore,
  setPhotoNameToStore,
  removePhotoFromStore,
} = newsfeedSlice.actions;

export default newsfeedSlice.reducer;
