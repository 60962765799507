import request from '@/utils/request';

const URL = '/api/user';

type HexColorString = string;
type ISODateString = string;
type ImageHTTPURL = string;

type Photos = {
  createdUserName: string;
  photoName: string;
  photoId: number;
  photoLinkId: number;
  photoUrl: ImageHTTPURL;
  created: ISODateString;
  createdBy: number;
  notes: string | null;
};

type NewsfeedDetail = {
  projectNewsfeedId: number;
  projectId: number;
  projectName: string;
  projectColor: HexColorString;
  activityId: number;
  activityName: string;
  date: ISODateString;
  newsTemplateId: number;
  photoCount: number;
  createdUserName: string;
  photos: Photos[];
};

export function QueryNewsfeed(groupId) {
  return request({
    url: `${URL}/QueryNewsfeed?GroupId=${groupId}`,
    method: 'GET',
  });
}

export function GetNewsfeedDetail(newsfeedId) {
  return request({
    url: `${URL}/GetNewsfeedDetail?newsfeedId=${newsfeedId}`,
    method: 'GET',
  });
}

export async function queryNewsfeedAndWeeklyReportWithPaged(params) {
  const urlParams = new URLSearchParams(params).toString();
  return request(`${URL}/queryNewsfeedAndWeeklyReportWithPaged?${urlParams}`);
}
