import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import userSlice from '@/lib/users/userSlice';
import adminConsoleMemberSlice from '@/lib/adminConsole/member/memberSlice';
import accountSlice from '@/lib/account/accountSlice';
import paymentSlice from '@/lib/payment/paymentSlice';
import dialogsSlice from '@/lib/dialogs/dialogsSlice';
import taskListSlice from '@/lib/taskList/taskListSlice';
import appSlice from '@/lib/app/appSlice';
import groupSlice from '@/lib/group/groupSlice';
import clockSlice from '@/lib/clock/clockSlice';
import newsfeedSlice from './newsfeed/newsfeedSlice';
import scheduleSlice from './schedule/scheduleSlice';
// RTKQuery
import { projectsApi } from '@/lib/projects/projectsAPI';
import { taskListApi } from '@/lib/taskList/taskListAPI';
import { usersApi } from '@/lib/users/usersAPI';
import { reportAPI } from '@/lib/report/reportAPI';
import { activityAPI } from '@/lib/activity/activityAPI';
import { scheduleApi } from './schedule/scheduleAPI';
import { notifyMessageApi } from '@/lib/notifyMessage/notifyMessageAPI';
import { clockApi } from './clock/clockAPI';

const store = configureStore({
  reducer: {
    user: userSlice,
    adminConsoleMember: adminConsoleMemberSlice,
    account: accountSlice,
    payment: paymentSlice,
    taskList: taskListSlice,
    app: appSlice,
    group: groupSlice,
    dialogs: dialogsSlice,
    newsfeed: newsfeedSlice,
    clock: clockSlice,
    schedule: scheduleSlice,

    // RTKQuery
    [projectsApi.reducerPath]: projectsApi.reducer,
    [taskListApi.reducerPath]: taskListApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [reportAPI.reducerPath]: reportAPI.reducer,
    [activityAPI.reducerPath]: activityAPI.reducer,
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [notifyMessageApi.reducerPath]: notifyMessageApi.reducer,
    [clockApi.reducerPath]: clockApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      projectsApi.middleware,
      taskListApi.middleware,
      usersApi.middleware,
      reportAPI.middleware,
      activityAPI.middleware,
      scheduleApi.middleware,
      notifyMessageApi.middleware,
      clockApi.middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
